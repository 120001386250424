<template>
  <div class="main-body">
    <div>
      <nav-bar></nav-bar>
    </div>
    <div class="main-container">
      <div class="login-container">
        <!-- 登录标题 -->
        <div>
          <h3>登录{{siteInfo.info ? siteInfo.info.sitename : ''}}</h3>
        </div>
        <!-- 登录区域 -->
        <div class="login-tabs">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="username-login">
              <template #label>
                <span>
                   <svg-icon icon-class="touxiang"></svg-icon>
                   <span>账号登录</span>
                </span>
              </template>
              <div class="username-login">
                <div class="input username">
                  <input type="text" placeholder="用户名称/手机号码" v-model="phoneOrUser">
                </div>
                <div class="input password">
                  <input type="password" placeholder="密码" v-model="password">
                </div>
                <div class="cannot-login">
                  <span>
                    还没账号？
                    <a href="/register" class="register-immediately">立即注册</a>
                  </span>
                  <span>
                    <a href="/forget">忘记密码</a>
                  </span>
                </div>
                <div class="login-button">
                  <el-button :type="$config.buttonType" class="button" size="medium" @click="toLogin">登录</el-button>  
                </div> 
              </div>
            </el-tab-pane>
            <el-tab-pane name="sms-login">
              <template #label>
                <span>
                   <svg-icon icon-class="xinxiduanxinxiaoxitixingyoujiansixinyouxiang"></svg-icon>
                   <span>短信登录</span>
                </span>
              </template>
              <div class="sms-login">
                <div class="input mobile">
                  <input type="text" placeholder="手机号码" v-model="phone">
                </div>
                <div class="check-code-container">
                  <div class="input check-code">
                    <input type="text" placeholder="短信验证码" v-model="sms">
                  </div>
                  <span class="ml-1">
                      <el-button :type="$config.buttonType" @click="getLoginCheckCode">发送验证码</el-button>
                  </span>
                </div>
                <div class="cannot-login">
                  &nbsp;
                </div>
                <div class="login-button">
                  <el-button :type="$config.buttonType" class="button" size="medium" @click="toSmsLogin">登录</el-button>  
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <el-divider></el-divider>
        <!-- 登录协议 -->
        <div class="protocol-container">
          登录即代表您已阅读并同意
          <a href="/protocol/1">《用户协议》</a>
          <a href="/protocol/2">《隐私协议》</a>
        </div>
      </div>
    </div>
    <div>
      <web-footer></web-footer>
    </div>
  </div>
</template>

<script type="text/javascript">
import NavBar from '@/views/user/components/NavBar'
import WebFooter from '@/views/user/components/WebFooter'
import {getLocalStorage,setLocalStorage,getSessionStorage,removeLocalStorage,removeSessionStorage} from '@/utils/auth'
import {userLogin,getUserInfo,getLoginSmsCheckCode,loginBySms} from '@/api/user'

export default {
  data() {
    return {
      siteInfo:'',
      phoneOrUser:'',
      password:'',
      phone:'',
      sms:'',
      checkCode:'',
      activeName:'username-login',
      loginType:'username' //默认是username登录模式
    }
  },
  components: {
    NavBar,
    WebFooter
  },
  props:{

  },
  created(){
    //清除即存信息
    removeLocalStorage("token")
    removeLocalStorage("userInfo")
		// removeLocalStorage("mer")
    this.findSiteInfo()
  },
  methods:{
    findSiteInfo(){
      this.siteInfo = getLocalStorage('siteInfo')
    },
    toLogin(){
      if(this.phoneOrUser && this.password){
        userLogin({
          phone:this.phoneOrUser,
          password:this.password
        }).then(res => {
          if(res.code == 1){
            setLocalStorage('token',res.data)
            getUserInfo().then(
              res1=>{
                if(res1.code == 1){
                  setLocalStorage('userInfo',res1.data)
                }
              }
            )
            this.$notify.success({
              title: '成功',
              message: '系统登录成功！',
            });
            //登录成功后，判断sessionStorage中是否有之前的路由，有的话直接跳转过去
            let path = getSessionStorage('previousRoute') || null
						removeSessionStorage("previousRoute")
            if(path){
              this.$router.push(path)
            }else{
							//登录成功跳转到个人中心（我的课程中心）
              this.$router.push("/user/mycourse")
            }
          }else{
            this.$notify.error({
              title: '失败',
              message: '用户名或密码错误！',
            });
          }
        })
      }
    },
    toSmsLogin(){
      //判断手机号码
      if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))){ 
        this.$notify.error({
          title:'错误',
          message:'手机号' + this.phone + '格式有误！'
        })
        return; 
      }
      //判断验证码
      if(this.sms != this.checkCode || this.sms.trim() == ''){
        this.$notify.error({
          title:'错误',
          message:'验证码错误！'
        })
        return
      }
      //短信登录
      loginBySms({
        phone:this.phone,
        code:this.sms
      }).then(
        res => {
          if(res.code == 1){
            setLocalStorage('token',res.data)
            getUserInfo().then(
              res1=>{
                if(res1.code == 1){
                  setLocalStorage('userInfo',res1.data)
                }
              }
            )
            this.$notify.success({
              title: '成功',
              message: '系统登录成功！',
            });
            //登录成功后，判断sessionStorage中是否有之前的路由，有的话直接跳转过去
            let path = getSessionStorage('previousRoute') || null
            if(path){
              this.$router.push(path)
            }else{
              this.$router.push("/user/mycourse")
            }
          }else{
            this.$notify.error({
              title: '失败',
              message: '系统登录失败，请稍后重试！',
            });
          }
        }
      )
    },
    handleClick(tab,event){
      if(tab.props.name == "username-login"){
        this.loginType = 'username'
      }else{
        this.loginType = 'sms'
      }
    },
    getLoginCheckCode(){
      if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))){ 
        this.$notify.error({
          title:'错误',
          message:'手机号' + this.phone + '格式有误！'
        })
        return; 
      }
      getLoginSmsCheckCode({
        phone:this.phone
      }).then(
        res => {
          if(res.code == 1){
            this.checkCode = res.data
            this.$notify.success({
              title:'成功',
              message:'验证码已发送！'
            })
          }else{
            this.$notify.error({
              title:'错误',
              message:'手机号' + res.data.phone + '未注册！'
            })
          }
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.main-body{
  background-image:url('../../assets/images/login-cover-5.jpg');
  background-position:center top;
  background-repeat: no-repeat;
  display:flex;
  flex-direction: column;
  .main-container{
    position: relative;
    width:1200px;
    margin:0px auto;
    min-height:570px;
    height:700px;
    max-height:760px;
    .login-container{
      position: absolute;
      height:450px;
      width:400px;
      right:0px;
      top:50px;
      background-color:white;
      .username-login{
        height:200px;
      }
      .sms-login{
        height:200px;
      }
      h3{
        text-align: center;
        font-size:18px;
        font-weight:bold;
        height:80px;
        line-height: 80px;
      }
      .input{
        display:block;
        height:20px;
        width:270px;
        padding:8px;
        outline:medium;
        color:$text-color;
        border:solid 1px #aaa;
        border-radius: 0px;
        margin-left:20px;
        margin-top:30px;
        padding-left:35px;
        input{
          font-size:16px;
          height:20px;
          width:260px;
          color:#333;
        }
      }
      .username{
        background-image:url('../../assets/images/login-icon-user.png');
        background-repeat: no-repeat;
        background-position: 5px center;
      }
      .password{
        background-image:url('../../assets/images/login-icon-lock.png');
        background-repeat: no-repeat;
        background-position: 5px center;
      }
      .mobile{
        background-image:url('../../assets/images/login-icon-mobile.png');
        background-repeat: no-repeat;
        background-position: 5px center;
      }
      .check-code-container{
        margin-top:30px;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .check-code{
          margin-top:0px;
          background-image:url('../../assets/images/login-icon-checkcode.png');
          background-repeat: no-repeat;
          background-position: 5px center;
          width:140px;
          input{
            width:140px;
          }
        }
      }
      .cannot-login{
        width:320px;
        margin:10px auto;
        display:flex;
        flex-direction: row;
        justify-content:space-between;
        .register-immediately{
          color:$theme-color;
        }
      }
      .login-button{
        width:320px;
        margin:10px auto;
        height:30px;
        .button{
          width:100%;
        }
      }
      .protocol-container{
        width:350px;
        margin:0px auto;
        line-height:20px;
        a{
          color:$theme-color;
          &:hover{
            color:#333;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.login-tabs{
  width:360px;
  margin:0px auto;
  .el-tabs__item{
    width:180px;
    text-align: center;
    font-size:16px;
    font-weight:bold;
  }
  .el-tabs__item:hover{
    color:$theme-color;
  }
  .el-tabs__item.is-active{
    color:$theme-color;
  }
  .el-tabs__active-bar{
    background-color:$theme-color;
  }
}
</style>